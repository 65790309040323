h3 {
  display:inline;
}

li {
  margin: 12px;
}

/* This will be the yellow arrow button the user clicks to view the hidden links list */
button {

  background-color: #EE0;
  border-radius:5px;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 4px;
  border: none;
}

button:hover {

  cursor: pointer;
}

/* make the clickable button larger on smaller screens so it's easier to click on */
@media screen and (max-width: 600px) {

  button {
    padding-left: 10px;
    padding-right: 10px;
  }
}
