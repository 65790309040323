.sidemenu {
  background-color: #F44;
  flex: 1;
  border-bottom-right-radius: 20px;
}

.sidemenu a {

  text-decoration: none;
  font-weight: bold;
}

.sidemenu ul {

  list-style: none;
}

.sidemenu li {

  width: 200px;
  background-color: #F88;
  margin: 15px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

.sidemenu li:hover {

  background-color: #FAA;
}

.sidemenu button {

  padding: 5px;
  font-size: 30px;
  margin:10px;
}
.menu-cat-buttons {

  width: 200px;
  margin: 15px;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  font-style: oblique;
  font-weight: bolder;
}

/* Sidemenu will not initially be visible on mobile. Hamburger icon will need to
be clicked in order to reveal the menu. This is done with absolute positioning. A
transition is used to make the sidemenu pop in and out smoothly. */
@media screen and (max-width: 600px) {

  .sidemenu {
    position: absolute;
    left: -290px;
    transition: left 0.75s;
  }
}
